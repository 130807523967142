import * as React from 'react';
import { PageProps } from 'gatsby';
import { makeStyles, Typography } from '@material-ui/core';
import { Helmet } from 'react-helmet';

interface Props extends PageProps {}

const NotFoundPage: React.FC<Props> = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Helmet>
        <title>404 | Ruonavaara</title>
      </Helmet>
      <Typography variant="h1" align="center">
        404
      </Typography>
      <Typography variant="body1" align="center">
        The page you tried to find no longer exists.
      </Typography>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
    color: theme.palette.primary.contrastText,
  },
}));

export default NotFoundPage;
